import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addPhraseInException, changeActionException, changeActionTypeException, changeMaxNumberException, changeScriptException, changeServiceException, deleteRobotException, selectActiveRobotVersion } from '../../store/sesRobotSlice';
import { selectScriptList } from '../../store/sesScriptSlice';
import { selectCategoriesList } from '../../store/qasSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { SES } from '../../constants/accessRights';
import { ACTION_LIST, ACTION_TYPE_LIST, SERVICE_TYPE_LIST } from '../../constants/robotConfigLists';
import { colorPrimary, colorRed } from '../../constants/colors';
import { Action, ActionType, ServiceType } from '../../types/sesTypes';
import { IActionExceptionInternal } from '../../types/sesRobotTypes';
import { CachingType } from '../../types/qasTypes';
import Phrase from './Phrase/Phrase';
import FormAddingCategoryDataInException from '../Forms/FormAddingCategoryDataInException/FormAddingCategoryDataInException';
import FormAddingConditionRunActionException from '../Forms/FormAddingConditionRunActionException/FormAddingConditionRunActionException';
import CategoryData from './CategoryData/CategoryData';
import ConditionData from './ConditionData/ConditionData';
import { IActionExceptionProps } from './ActionException.props';
import styles from './ActionException.module.scss';

const ActionException = ({ action, exception, setChangeFlg }: IActionExceptionProps): JSX.Element => {
	const [maxNumberExceptions, setMaxNumberExceptions] = useState<number>(1); // максимальное количество исключений подряд
	const [selectAction, setSelectAction] = useState<Action>('say'); // действие
	const [selectActionType, setSelectActionType] = useState<ActionType>('linear'); // тип действия
	const [script, setScript] = useState<string>(''); // скрипт
	const [service, setService] = useState<ServiceType>('qas'); // сервис
	const [selectCategoryId, setSelectCategoryId] = useState<string>(''); // id категории
	const [selectCaching, setSelectCaching] = useState<CachingType>('checked'); // кеширование
	const [checkGPT, setCheckGPT] = useState<boolean>(false); // использование gpt
	const [checkCreative, setCheckCreative] = useState<boolean>(false); // использование творческого режима
	const [addAlias, setAddAlias] = useState<boolean>(false); // добавление вопроса в похожие в кэше, если похожая формулировка найдена

	const [showCategoriesSettings, setShowCategoriesSettings] = useState<boolean>(false); // показ настроек категорий
	const [showAdditionalSettings, setShowAdditionalSettings] = useState<boolean>(false); // показ доп.настроек

	const dispatch = useAppDispatch();
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий
	const scriptList = useAppSelector(selectScriptList); // store - список скриптов
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за типом исключения и вписываем значения в поля
	useEffect(() => {
		setMaxNumberExceptions(action.max);
		setSelectAction(action.action);
		action.action === 'say' && setSelectActionType(action.type);
		action.action === 'say' && action.type === 'external' && setScript(action.script);
		if (action.action === 'say' && action.type === 'internal') {
			setScript(action.service);
			setSelectCategoryId(Object.keys(action.categories || {})[0] || '');
			setSelectCaching(action.useCache);
			setCheckGPT(action.useGPT === 'yes' ? true : false);
			setCheckCreative(action.creative === 'yes' ? true : false);
			setAddAlias(action.addAlias === 'yes' ? true : false);
		}
	}, [exception]);

	// следим за действием
	useEffect(() => {
		// если касается действий с фразами
		if (action.action === 'say' && (action.type === 'linear' || action.type === 'random')) {
			// есть права на редактирование и последняя фраза не пустая - добавляем новую
			isAccess(SES.ROBOT_EDIT) && action.text[action.text.length - 1] !== '' && activeRobotVersion === 'draft' && addPhraseHandler();
		}
	}, [action]);

	// следим за количеством исключений
	useEffect(() => {
		// через пол-секунды бездействия после окончания ввода количества
		const handler = setTimeout(() => {
			if (action.max !== maxNumberExceptions) {
				dispatch(changeMaxNumberException({ exception, max: maxNumberExceptions })); // изменияем максимальное количество исключений
				setChangeFlg(true);
			}
		}, 500);

		return () => {
			clearTimeout(handler); // сбрасываем timeout, если продолжается ввод
		};
	}, [maxNumberExceptions]);

	// обработчик изменения действия
	const changeActionHandler = (e: SelectChangeEvent<Action>): void => {
		setSelectAction(e.target.value as Action);
		dispatch(changeActionException({ exception, action: e.target.value as Action }));
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик изменения типа действия
	const changeActionTypeHandler = (e: SelectChangeEvent<ActionType>): void => {
		setSelectActionType(e.target.value as ActionType);
		dispatch(changeActionTypeException({
			exception,
			action: selectAction,
			actionType: e.target.value as ActionType,
			text: (action.action === 'say' && (action.type === 'linear' || action.type === 'random') && (e.target.value === 'linear' || e.target.value === 'random')) ? action.text : undefined,
		}));
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик удаления действия
	const deleteActionHandler = (): void => {
		dispatch(deleteRobotException(exception));
		setChangeFlg(true);
	};

	// обработчик добавления фразы
	const addPhraseHandler = (): void => {
		if (action.action === 'say' && (action.type === 'linear' || action.type === 'random')) {
			dispatch(addPhraseInException({
				exception, actionObj: {
					max: action.max,
					action: action.action,
					type: action.type,
					text: [...action.text, ''],
					conditions: action.conditions,
				}
			})); // перезапись действия исключения с добавлением фразы
			setChangeFlg(true); // ставим флаг о несохраненных данных
		}
	};

	// обработчик изменения скрипта
	const changeScriptHandler = (e: SelectChangeEvent<string>): void => {
		setScript(e.target.value);
		dispatch(changeScriptException({
			exception,
			script: e.target.value,
		}));
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик изменения сервиса
	const changeServiceHandler = (e: SelectChangeEvent<string>): void => {
		setService(e.target.value as ServiceType);
		dispatch(changeServiceException({
			exception,
			service: e.target.value as ServiceType,
			useCache: selectCaching,
			useGPT: checkGPT ? 'yes' : 'no',
			creative: checkCreative ? 'yes' : 'no',
			addAlias: addAlias ? 'yes' : 'no',
		}));
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик изменения использования кэша ответов
	const changeCachingHandler = (e: SelectChangeEvent<string>): void => {
		setSelectCaching(e.target.value as CachingType);
		dispatch(changeServiceException({
			exception,
			service,
			useCache: e.target.value as CachingType,
			useGPT: checkGPT ? 'yes' : 'no',
			creative: checkCreative ? 'yes' : 'no',
			addAlias: addAlias ? 'yes' : 'no',
		})); // робота
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик изменения использования генерации
	const changeGPTHandler = (e: ChangeEvent<HTMLInputElement>): void => {
		setCheckGPT(e.target.checked);
		dispatch(changeServiceException({
			exception,
			service,
			useCache: selectCaching,
			useGPT: e.target.checked ? 'yes' : 'no',
			creative: checkCreative ? 'yes' : 'no',
			addAlias: addAlias ? 'yes' : 'no',
		})); // робота
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик изменения использования творческого режима
	const changeCreativeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
		setCheckCreative(e.target.checked);
		dispatch(changeServiceException({
			exception,
			service,
			useCache: selectCaching,
			useGPT: checkGPT ? 'yes' : 'no',
			creative: e.target.checked ? 'yes' : 'no',
			addAlias: addAlias ? 'yes' : 'no',
		})); // робота
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик изменения использования кэширования алиасов
	const changeAddAliasHandler = (e: ChangeEvent<HTMLInputElement>): void => {
		setAddAlias(e.target.checked);
		dispatch(changeServiceException({
			exception,
			service,
			useCache: selectCaching,
			useGPT: checkGPT ? 'yes' : 'no',
			creative: checkCreative ? 'yes' : 'no',
			addAlias: e.target.checked ? 'yes' : 'no',
		})); // робота
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	return (
		<div className={styles.container}>
			<div className={styles.actionBlock}>
				{/* макс. кол-во исключений подряд */}
				<FormControl fullWidth margin='dense'>
					<TextField
						required
						label={translate('actionException_inputMaxException')}
						variant="outlined"
						type='number'
						disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
						value={maxNumberExceptions}
						onChange={(e) => setMaxNumberExceptions(+e.target.value)}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13,
								color: colorPrimary,
							},
							inputProps: { min: 1 }
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>

				{/* удаление действия */}
				{isAccess(SES.ROBOT_EDIT) && activeRobotVersion === 'draft' &&
					<FontAwesomeIcon
						icon={faTrashCan}
						color={colorRed}
						size='lg'
						onClick={deleteActionHandler}
						title={translate('actionException_deleteActionTitle')}
						style={{ cursor: 'pointer' }}
					/>
				}
			</div>
			<div className={styles.actionBlock}>
				{/* действие */}
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel sx={{ fontSize: 13 }}>{translate('actionException_selectAction')}</InputLabel>
					<Select
						required
						label={translate('actionException_selectAction')}
						disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
						value={selectAction}
						onChange={changeActionHandler}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						{ACTION_LIST.map(({ action, translation }) =>
							<MenuItem key={action} value={action} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
						)}
					</Select>
				</FormControl>

				{/* тип действия */}
				{selectAction === 'say' &&
					<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
						<InputLabel id="actionType-label" sx={{ fontSize: 13 }}>{translate('actionException_selectActionType')}</InputLabel>
						<Select
							required
							labelId="actionType-label"
							disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
							label={translate('actionException_selectActionType')}
							value={selectActionType}
							onChange={changeActionTypeHandler}
							style={{ fontSize: 13, height: 33, color: colorPrimary }}
						>
							{ACTION_TYPE_LIST.map(({ actionType, translation }) =>
								<MenuItem key={actionType} value={actionType} disabled={actionType === 'external' && !isAccess(SES.SCRIPT_LIST)} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
							)}
						</Select>
					</FormControl>
				}
			</div>

			{/* фразы */}
			{action.action === 'say' && (action.type === 'linear' || action.type === 'random') &&
				action.text.map((phrase, idxPhrase, arrayPhrases) => (
					<Phrase
						key={phrase + idxPhrase}
						phrase={phrase}
						idxPhrase={idxPhrase}
						arrayPhrases={arrayPhrases}
						exception={exception}
						setChangeFlg={setChangeFlg}
					/>
				))
			}

			{/* скрипт */}
			{selectAction === 'say' && selectActionType === 'external' &&
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel sx={{ fontSize: 13 }}>{translate('actionException_selectScript')}</InputLabel>
					<Select
						required
						label={translate('actionException_selectScript')}
						disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
						value={script}
						onChange={changeScriptHandler}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						{/* на случай, если не найден скрипт из списка */}
						{!scriptList.data.find(scriptItem => scriptItem.id === script) &&
							<MenuItem value={script} sx={{ fontSize: 12, color: colorRed }}>{script} ({translate('notFound')})</MenuItem>
						}
						{scriptList.data.map(({ id, name }) => (
							<MenuItem key={id} value={id} sx={{ fontSize: 13, color: colorPrimary }}>{name}</MenuItem>
						))}
					</Select>
				</FormControl>
			}

			{/* сервис */}
			{selectAction === 'say' && selectActionType === 'internal' &&
				<>
					{/* сервис */}
					<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
						<InputLabel sx={{ fontSize: 13 }}>{translate('actionException_selectService')}</InputLabel>
						<Select
							required
							label={translate('actionException_selectService')}
							disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
							value={service}
							onChange={changeServiceHandler}
							style={{ fontSize: 13, height: 33, color: colorPrimary }}
						>
							{SERVICE_TYPE_LIST.map(({ serviceType, translation }) =>
								<MenuItem key={serviceType} value={serviceType} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
							)}
						</Select>
					</FormControl>

					<div className={styles.additionally} onClick={() => setShowCategoriesSettings(prev => !prev)}>
						{translate('actionException_categoryTitle')}
					</div>

					{/* категория */}
					{showCategoriesSettings &&
						<>
							<FormControl fullWidth margin='dense' sx={{
								'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
								'.MuiInputBase-input': { padding: '8px 14px' },
							}}>
								<InputLabel id="category-label" sx={{ fontSize: 13 }}>{translate('answerToQuestion_selectCategory')}</InputLabel>
								<Select
									labelId="category-label"
									id="category"
									label={translate('answerToQuestion_selectCategory')}
									value={selectCategoryId}
									onChange={e => setSelectCategoryId(e.target.value)}
									style={{ fontSize: 13, height: 33, color: colorPrimary }}
								>
									{/* на случай, если не найдена категория */}
									{(Object.keys((action as IActionExceptionInternal)?.categories || {}).map(categoryId => {
										if (!categoriesList.data.find(categoryItem => categoryItem.id === categoryId)) {
											return (
												<MenuItem key={categoryId} value={categoryId} sx={{ fontSize: 12, color: colorRed }}>{categoryId} ({translate('notFound')})</MenuItem>
											);
										} else return undefined;
									}))}
									{categoriesList.data.map((category) => (
										<MenuItem key={category.id} value={category.id} sx={{ fontSize: 13, color: colorPrimary }}>
											{category.name} {(action as IActionExceptionInternal).categories?.[category.id]?.length > 0 && `(${(action as IActionExceptionInternal).categories[category.id].length})`}
										</MenuItem>
									))}
								</Select>
							</FormControl>

							{/* блок условий категорий */}
							{((selectCategoryId !== '' && activeRobotVersion === 'draft') || (action as IActionExceptionInternal).categories?.[selectCategoryId]?.length > 0) &&
								// блок ИЛИ
								<>
									{(action as IActionExceptionInternal).categories?.[selectCategoryId]?.map((categoryDataBlock, categoryBlockIdx) =>
										<Fragment key={categoryDataBlock.toString() + categoryBlockIdx}>
											<fieldset className={styles.categoryDataBlock}>
												<legend>{translate('actionException_conditionFieldsetLegend')}</legend>
												{categoryDataBlock?.map((categoryData, categoryIdx) => (
													<Fragment key={categoryData.data + categoryData.value + categoryBlockIdx + categoryIdx}>
														{/* блоки И */}
														<CategoryData
															categoryData={categoryData}
															exception={exception}
															categoryId={selectCategoryId}
															categoryBlockIdx={categoryBlockIdx}
															categoryIdx={categoryIdx}
															setChangeFlg={setChangeFlg}
														/>
														<div className={styles.categoryDataBlockLine}>
															<span className={styles.categoryDataBlockLineTitle}>{translate('actionEvent_conditionFieldsetLegendLineAnd')}</span>
														</div>
													</Fragment>
												))}

												{isAccess(SES.ROBOT_EDIT) && activeRobotVersion === 'draft' &&
													<FormAddingCategoryDataInException
														exception={exception}
														categoryId={selectCategoryId}
														categoryBlockIdx={categoryBlockIdx}
														setChangeFlg={setChangeFlg}
													/>
												}
											</fieldset>
											<div className={styles.categoryDataBlockLine}>
												<span className={styles.categoryDataBlockLineTitle}>{translate('actionEvent_conditionFieldsetLegendLineOr')}</span>
											</div>
										</Fragment>
									)}
									<fieldset className={styles.categoryDataBlock}>
										<legend>{translate('actionException_conditionFieldsetLegend')}</legend>
										{isAccess(SES.ROBOT_EDIT) && activeRobotVersion === 'draft' &&
											<FormAddingCategoryDataInException
												exception={exception}
												categoryId={selectCategoryId}
												categoryBlockIdx={(action as IActionExceptionInternal).categories?.[selectCategoryId]?.length || 0}
												setChangeFlg={setChangeFlg}
											/>
										}
									</fieldset>
								</>
							}
						</>
					}

					{/* кэш ответов */}
					<FormControl fullWidth margin='dense' sx={{
						'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
						'.MuiInputBase-input': { padding: '8px 14px' },
					}}>
						<InputLabel id="caching-label" sx={{ fontSize: 13 }}>{translate('answerToQuestion_selectCacheAnswers')}</InputLabel>
						<Select
							labelId="caching-label"
							id="caching"
							label={translate('answerToQuestion_selectCacheAnswers')}
							disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
							value={selectCaching}
							onChange={changeCachingHandler}
							style={{ fontSize: 13, height: 33, color: colorPrimary }}
						>
							<MenuItem value='no' sx={{ fontSize: 13, color: colorPrimary }}>{translate('answerToQuestion_selectCacheNo')}</MenuItem>
							<MenuItem value='all' sx={{ fontSize: 13, color: colorPrimary }}>{translate('answerToQuestion_selectCacheAll')}</MenuItem>
							<MenuItem value='checked' sx={{ fontSize: 13, color: colorPrimary }}>{translate('answerToQuestion_selectCacheChecked')}</MenuItem>
						</Select>
					</FormControl>

					<FormControlLabel sx={{ width: '100%', overflow: 'hidden', marginTop: '-5px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
						<Checkbox
							checked={checkGPT}
							disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
							onChange={changeGPTHandler}
							size='small'
						/>
					} label={translate('answerToQuestion_checkGpt')} />
					<FormControlLabel sx={{ width: '100%', overflow: 'hidden', marginTop: '-15px', marginBottom: '-10px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
						<Checkbox
							checked={checkCreative}
							disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft' /* || !checkGPT */}
							onChange={changeCreativeHandler}
							size='small'
						/>
					} label={translate('answerToQuestion_checkCreative')} />
					<FormControlLabel sx={{ width: '100%', overflow: 'hidden', marginTop: '-3px', marginBottom: '-10px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px', textAlign: 'left' } }} control={
						<Checkbox
							checked={addAlias}
							disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
							onChange={changeAddAliasHandler}
							size='small'
						/>
					} label={translate('answerToQuestion_checkCacheAlias')} />
				</>
			}

			<div className={styles.additionally} onClick={() => setShowAdditionalSettings(prev => !prev)}>
				{translate('actionException_conditionRunActionTitle')}
			</div>

			{showAdditionalSettings &&
				// блоки ИЛИ
				<>
					{action.conditions?.map((conditionDataBlock, conditionBlockIdx) => (
						<Fragment key={conditionDataBlock.toString() + conditionBlockIdx}>
							<fieldset className={styles.categoryDataBlock}>
								<legend>{translate('actionEvent_conditionFieldsetLegend')}</legend>
								{conditionDataBlock?.map((conditionData, conditionIdx) => (
									<Fragment key={conditionData.type + conditionData.value + conditionBlockIdx + conditionIdx}>
										{/* блоки И */}
										<ConditionData
											conditionData={conditionData}
											exception={exception}
											conditionBlockIdx={conditionBlockIdx}
											conditionIdx={conditionIdx}
											setChangeFlg={setChangeFlg}
										/>
										<div className={styles.categoryDataBlockLine}>
											<span className={styles.categoryDataBlockLineTitle}>{translate('actionEvent_conditionFieldsetLegendLineAnd')}</span>
										</div>
									</Fragment>
								))}

								{isAccess(SES.ROBOT_EDIT) && activeRobotVersion === 'draft' &&
									<FormAddingConditionRunActionException
										exception={exception}
										conditionBlockIdx={conditionBlockIdx}
										setChangeFlg={setChangeFlg}
									/>
								}
							</fieldset>
							<div className={styles.categoryDataBlockLine}>
								<span className={styles.categoryDataBlockLineTitle}>{translate('actionEvent_conditionFieldsetLegendLineOr')}</span>
							</div>
						</Fragment>
					))}
					<fieldset className={styles.categoryDataBlock}>
						<legend>{translate('actionEvent_conditionFieldsetLegend')}</legend>
						{isAccess(SES.ROBOT_EDIT) && activeRobotVersion === 'draft' &&
							<FormAddingConditionRunActionException
								exception={exception}
								conditionBlockIdx={action.conditions?.length || 0}
								setChangeFlg={setChangeFlg}
							/>
						}
					</fieldset>
				</>
			}
		</div>
	);
};

export default ActionException;
