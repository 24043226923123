import { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector, } from '../../../store/hooks';
import { changeTypeConditionRunActionExceptionRobot, changeValuesConditionRunActionExceptionRobot, deleteConditionRunActionExceptionRobot, selectActiveRobotVersion, selectRobot } from '../../../store/sesRobotSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SES } from '../../../constants/accessRights';
import { CONDITION_RUN_ACTION_TYPE_LIST } from '../../../constants/robotConfigLists';
import { colorPrimary, colorRed } from '../../../constants/colors';
import { ConditionType, ConditionVariantType } from '../../../types/sesTypes';
import { IConditionDataProps } from './ConditionData.props';
import styles from './ConditionData.module.scss';

const ConditionData = ({ conditionData, exception, conditionBlockIdx, conditionIdx, setChangeFlg }: IConditionDataProps): JSX.Element => {
	const [conditionType, setConditionType] = useState<ConditionType>(conditionData.type); // тип условия запуска действия
	const [inputValue, setInputValue] = useState<string>(conditionData.value); // значение
	const [inputId, setInputId] = useState<string>((conditionData.type === 'data' || conditionData.type === 'variable') ? conditionData.id : ''); // id
	const [inputOperation, setInputOperation] = useState<string>((conditionData.type === 'data' || conditionData.type === 'variable') ? conditionData.operation : ''); // операция

	const dispatch = useAppDispatch();
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота
	const robotInfo = useAppSelector(selectRobot); // store - информация о роботе

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	const creatingObjectWithCondition = (type: ConditionType): ConditionVariantType => {
		switch (type) {
			case 'data':
			case 'variable':
				return {
					type,
					id: inputId,
					operation: inputOperation,
					value: inputValue,
				};
			case 'interval':
				return {
					type,
					value: inputValue,
				};
			default:
				const defaultType: never = type;
				return defaultType;
		};
	};

	// обработчик изменения типа условия запуска действия
	const changeTypeConditionRunActionHandler = (e: SelectChangeEvent<ConditionType>): void => {
		setConditionType(e.target.value as ConditionType);
		dispatch(changeTypeConditionRunActionExceptionRobot({
			exception,
			conditionBlockIdx,
			conditionIdx,
			conditionType: e.target.value as ConditionType,
		}));
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик изменения значений условия запуска действия
	const changeValuesConditionRunActionHandler = (): void => {
		dispatch(changeValuesConditionRunActionExceptionRobot({
			exception,
			conditionBlockIdx,
			conditionIdx,
			data: creatingObjectWithCondition(conditionData.type),
		}));
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик удаления условия
	const deleteConditionHandler = (): void => {
		dispatch(deleteConditionRunActionExceptionRobot({
			exception,
			conditionBlockIdx,
			conditionIdx,
		}));
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	return (
		<div className={styles.conditionData}>
			<div className={styles.conditionDataInner}>
				<div className={styles.conditionDataBlock}>
					{/* тип условия запуска действия */}
					<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
						<InputLabel sx={{ fontSize: 13 }}>{translate('conditionData_selectType')}</InputLabel>
						<Select
							required
							label={translate('conditionData_selectType')}
							value={conditionType}
							onChange={changeTypeConditionRunActionHandler}
							disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
							style={{ fontSize: 13, height: 33, color: colorPrimary }}
						>
							{CONDITION_RUN_ACTION_TYPE_LIST.map(({ type, translation }) =>
								<MenuItem key={type} value={type} sx={{ fontSize: 13, color: colorPrimary }}>
									{translate(translation)}
								</MenuItem>
							)}
						</Select>
					</FormControl>

					{/* значение */}
					{conditionType === 'interval' ?
						<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
							<InputLabel sx={{ fontSize: 13 }}>{translate('conditionData_inputValue')}</InputLabel>
							<Select
								label={translate('conditionData_inputValue')}
								disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
								value={inputValue}
								onChange={(e) => setInputValue(e.target.value)}
								onBlur={changeValuesConditionRunActionHandler}
								style={{ fontSize: 13, height: 33, color: colorPrimary }}
							>
								<MenuItem value='' sx={{ fontSize: 12, color: colorPrimary }}>{translate('Не выбран')}</MenuItem>
								{/* на случай, если не найден интервал из списка */}
								{robotInfo.data && !Object.keys(robotInfo.data.timeintervals).includes(inputValue) && inputValue !== '' &&
									<MenuItem value={inputValue} sx={{ fontSize: 12, color: colorPrimary }}>{inputValue} ({translate('notFound')})</MenuItem>
								}
								{robotInfo.data && Object.keys(robotInfo.data.timeintervals).map((name) =>
									<MenuItem key={name} value={name} sx={{ fontSize: 13, color: colorPrimary }}>{name}</MenuItem>
								)}
							</Select>
						</FormControl>
						:
						<FormControl fullWidth margin='dense'>
							<TextField
								label={translate('conditionData_inputValue')}
								variant="outlined"
								value={inputValue}
								onChange={(e) => setInputValue(e.target.value)}
								onBlur={changeValuesConditionRunActionHandler}
								disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
								InputProps={{
									style: {
										height: 33,
										fontSize: 13,
										color: colorPrimary,
									},
								}}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
							/>
						</FormControl>
					}
				</div>

				{(conditionType === 'data' || conditionType === 'variable') &&
					<div className={styles.conditionDataBlock}>
						{/* id */}
						<FormControl fullWidth margin='dense'>
							<TextField
								label={translate('conditionData_inputId')}
								variant="outlined"
								value={inputId}
								onChange={(e) => setInputId(e.target.value)}
								onBlur={changeValuesConditionRunActionHandler}
								disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
								InputProps={{
									style: {
										height: 33,
										fontSize: 13,
										color: colorPrimary,
									},
								}}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
							/>
						</FormControl>
						{/* операция */}
						<FormControl fullWidth margin='dense'>
							<TextField
								label={translate('conditionData_inputOperation')}
								variant="outlined"
								value={inputOperation}
								onChange={(e) => setInputOperation(e.target.value)}
								onBlur={changeValuesConditionRunActionHandler}
								disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
								InputProps={{
									style: {
										height: 33,
										fontSize: 13,
										color: colorPrimary,
									},
								}}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
							/>
						</FormControl>
					</div>
				}
			</div>

			{/* удаление условия */}
			{isAccess(SES.ROBOT_EDIT) && activeRobotVersion === 'draft' &&
				<FontAwesomeIcon
					icon={faTrashCan}
					color={colorRed}
					size='lg'
					onClick={deleteConditionHandler}
					title={translate('conditionData_deleteConditionBtn')}
					style={{ cursor: 'pointer' }}
				/>
			}
		</div>
	);
};

export default ConditionData;
