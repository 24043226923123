import { useEffect, useState } from 'react';
import { Autocomplete, Button, ButtonGroup, Fade, FormControl, Slide, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addEndpoint, clearEndpointElem, deleteEndpoint, editEndpoint, editEndpointName, selectDataList, selectEditingEndpoint, selectEndpointElem } from '../../store/sesSlice';
import { selectActiveRobotId, selectActiveRobotVersion, } from '../../store/sesRobotSlice';
import { selectChannelList } from '../../store/qasSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { SES } from '../../constants/accessRights';
import { colorPrimary } from '../../constants/colors';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import ConditionEntry from '../ConditionEntry/ConditionEntry';
import ConditionRun from '../ConditionRun/ConditionRun';
import ActionEvent from '../ActionEvent/ActionEvent';
import FormAddingConditionEntry from '../Forms/FormAddingConditionEntry/FormAddingConditionEntry';
import FormAddingConditionRun from '../Forms/FormAddingConditionRun/FormAddingConditionRun';
import FormAddingAction from '../Forms/FormAddingAction/FormAddingAction';
import AlertDialog from '../AlertDialog/AlertDialog';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { IConfigEndpointProps } from './ConfigEndpoint.props';
import styles from './ConfigEndpoint.module.scss';

const ConfigEndpoint = ({ showEndpointElem, setShowEndpointElem, containerRef }: IConfigEndpointProps): JSX.Element => {
	const [inputEndpointName, setInputEndpointName] = useState<string>(''); // название конечной точки
	const [inputChannel, setInputChannel] = useState<string>('default'); // канал
	const [inputFilterByDataElem, setInputFilterByDataElem] = useState<string>(''); // значение для фильтрации по элементу

	const [visibleBlockConfig, setVisibleBlockConfig] = useState<'entry' | 'run' | 'actions'>('entry'); // отображение условий/действий
	const [changeFlg, setChangeFlg] = useState<boolean>(false); // флаг, уведомляющий об изменении данных и возможности сохранить эти изменения
	const [showAlertDialogDel, setShowAlertDialogDel] = useState<boolean>(false); // показ диалогового окна при удалении конечной точки

	const dispatch = useAppDispatch();
	const activeRobotId = useAppSelector(selectActiveRobotId); // store - id активного робота
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота
	const endpointElem = useAppSelector(selectEndpointElem); // store - конечная точка
	const editingEndpoint = useAppSelector(selectEditingEndpoint); // store - статус изменения конечной точки
	const dataElemList = useAppSelector(selectDataList); // список элементов данных
	const channelList = useAppSelector(selectChannelList); // store - список каналов

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за открытием вкладки
	useEffect(() => {
		// при открытии - вешаем обработчики
		if (showEndpointElem) {
			document.addEventListener('mousedown', closeHandler); // вешаем обработчик на нажатие клавиши мыши
			document.addEventListener('keydown', keyDownHandler); // вешаем обработчик на нажатие клавиши клавиатуры
		}
		// при закрытии
		else {
			dispatch(clearEndpointElem()); // очищаем данные конечной точки
			setVisibleBlockConfig('entry'); // сбрасываем tab
			setChangeFlg(false); // сбрасываем флаг возможности сохранения
			// очищаем поля
			setInputEndpointName('');
			setInputChannel('default');
			setInputFilterByDataElem('');
		}

		// при закрытии - удаляем обработчики
		return () => {
			document.removeEventListener('mousedown', closeHandler);
			document.removeEventListener('keydown', keyDownHandler);
		};
	}, [showEndpointElem]);

	// следим за получением конечной точки
	useEffect(() => {
		// если есть данные - вписываем в поля
		if (endpointElem.endpoint) {
			!inputEndpointName && setInputEndpointName(endpointElem.endpoint.name);
		}
	}, [endpointElem.endpoint]);

	// следим за статусом сохранения конечной точки
	useEffect(() => {
		// если изменение конечной точки прошло успешно
		if (editingEndpoint.status === RequestStatus.IDLE && editingEndpoint.error === ResponseStatus.SUCCESS && editingEndpoint.message !== '') {
			endpointElem.endpoint && endpointElem.endpoint.name !== inputEndpointName && dispatch(editEndpointName({ endpointId: endpointElem.endpoint.id, endpointName: inputEndpointName })); // если изменилось имя - меняем в store
			setChangeFlg(false); // сбрасываем флаг о несохраненных данных
		}
	}, [editingEndpoint]);

	// обработчик добавления конечной точки
	const addEndpointHandler = (): void => {
		activeRobotId && dispatch(addEndpoint({
			robotId: activeRobotId,
			data: {
				name: inputEndpointName,
				entry: endpointElem.endpoint?.entry || [],
				run: endpointElem.endpoint?.run || [],
				actions: endpointElem.endpoint?.actions || {},
			}
		})); // добавляем точку
	};

	// обработчик сохранения конечной точки
	const editEndpointHandler = (): void => {
		endpointElem.endpoint && activeRobotId && dispatch(editEndpoint({
			robotId: activeRobotId,
			endpointId: endpointElem.endpoint.id,
			data: {
				name: inputEndpointName,
				entry: endpointElem.endpoint.entry,
				run: endpointElem.endpoint.run,
				actions: endpointElem.endpoint.actions,
			}
		})); // изменяем точку
	};

	// обработчик удаления конечной точки
	const deleteEndpointHandler = (): void => {
		setShowAlertDialogDel(false); // закрываем диалоговое окно
		endpointElem.endpoint && activeRobotId && dispatch(deleteEndpoint({ robotId: activeRobotId, endpointId: endpointElem.endpoint.id })); // удаление конечной точки
		closeHandler(); // закрываем вкладку
	};

	// обработчик нажатия клавиши
	const keyDownHandler = (e: KeyboardEvent): void => {
		if (e.code === 'Escape') closeHandler();
	};

	// обработчик закрытия вкладки
	const closeHandler = (): void => {
		setShowEndpointElem(false);
	};

	return (
		<Slide direction="right" in={showEndpointElem} mountOnEnter unmountOnExit container={containerRef.current}>
			<div className={styles.data} onMouseDown={(e) => e.stopPropagation()}>

				{/* загрузка конечной точки */}
				{endpointElem.status === RequestStatus.LOADING &&
					<div className={styles.loading}>
						<ProgressCircle title={translate('progressCircle_endpointLoadingTitle')} />
					</div>
				}

				{/* ошибка получения конечной точки */}
				{(endpointElem.status === RequestStatus.FAILED || endpointElem.error === ResponseStatus.FAILED) &&
					<div className={styles.failed}>
						{translate(endpointElem.message || 'configEndpoint_failedTitle')}
					</div>
				}

				{/* конечная точка */}
				{endpointElem.endpoint &&
					<Fade in={true} timeout={500}>
						<div className={styles.dataWrapper}>
							<div className={styles.dataTop}>
								<div className={styles.dataTopMain}>
									{/* имя */}
									<FormControl fullWidth sx={{ marginTop: '8px' }}>
										<TextField
											required
											label={translate('configEndpoint_inputName')}
											variant="outlined"
											disabled={!isAccess(SES.ENDPOINT_EDIT) || activeRobotVersion !== 'draft'}
											value={inputEndpointName}
											onChange={(e) => { setInputEndpointName(e.target.value); endpointElem.endpoint?.id && setChangeFlg(true); }}
											// error={showErrorExistingDataElem}
											// helperText={showErrorExistingDataElem ? translate('Элемент данных уже существует') : ''}
											InputProps={{
												style: {
													height: 33,
													fontSize: 13,
													color: colorPrimary,
												},
											}}
											InputLabelProps={{
												style: {
													fontSize: 13,
												},
											}}
											sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
										/>
									</FormControl>

									<ButtonGroup fullWidth>
										<Button
											variant={visibleBlockConfig === 'entry' ? "contained" : "outlined"}
											sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }}
											onClick={() => setVisibleBlockConfig('entry')}
										>
											{translate('configEndpoint_entryBtn')}
										</Button>
										<Button
											variant={visibleBlockConfig === 'run' ? "contained" : "outlined"}
											sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }}
											onClick={() => setVisibleBlockConfig('run')}
										>
											{translate('configEndpoint_runBtn')}
										</Button>
										<Button
											variant={visibleBlockConfig === 'actions' ? "contained" : "outlined"}
											sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }}
											onClick={() => setVisibleBlockConfig('actions')}
										>
											{translate('configEndpoint_actionBtn')}
										</Button>
									</ButtonGroup>

									{endpointElem.endpoint.id && visibleBlockConfig !== 'actions' &&
										<Autocomplete
											freeSolo
											autoHighlight
											openOnFocus
											options={dataElemList.data.map(dataElemItem => dataElemItem.name)}
											value={inputFilterByDataElem}
											onChange={(_, value) => setInputFilterByDataElem(value ? value : '')}
											renderInput={(params) =>
												<TextField
													{...params}
													label={translate('configEndpoint_inputFilter')}
													value={inputFilterByDataElem}
													onChange={(e) => setInputFilterByDataElem(e.target.value)}
													InputLabelProps={{
														style: {
															fontSize: 13,
														},
													}}
													sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
												/>
											}
											sx={{
												".MuiInputBase-root": { minHeight: 33, fontSize: 13, color: colorPrimary },
												".MuiOutlinedInput-root": { padding: '0 0 0 3px', paddingRight: "30px!important" },
												// ".MuiAutocomplete-popupIndicator": { display: 'none' },
												// backgroundColor: 'transparent',
											}}
											getOptionLabel={option => option}
											renderOption={(props, option) =>
												<li {...props} style={{ padding: '2px 5px', textAlign: 'left', fontSize: 12, color: colorPrimary }}>
													{option}
												</li>
											}
										/>
									}
								</div>

								{visibleBlockConfig === 'entry' &&
									<div className={styles.dataTopEntry}>
										{endpointElem.endpoint.entry.map((condition, idx) => {
											// фильтрация по элементу данных
											if (inputFilterByDataElem === '' || condition.map(dataElemId => dataElemList.dictionary[dataElemId]).find(dataElemName => dataElemName?.toLowerCase()?.includes(inputFilterByDataElem.toLowerCase()))) {
												return (
													<ConditionEntry
														condition={condition}
														setChangeFlg={setChangeFlg}
														idx={idx}
														key={condition.join('') + idx}
													/>
												);
											} else return undefined;
										})}
										{isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft' &&
											<FormAddingConditionEntry
												setChangeFlg={setChangeFlg}
											/>
										}
									</div>
								}

								{visibleBlockConfig === 'run' &&
									<div className={styles.dataTopRun}>
										{endpointElem.endpoint.run.map((condition, idx) => {
											// фильтрация по элементу данных
											if (inputFilterByDataElem === '' || dataElemList.dictionary[condition].toLowerCase()?.includes(inputFilterByDataElem.toLowerCase())) {
												return (
													<ConditionRun
														condition={condition}
														setChangeFlg={setChangeFlg}
														idx={idx}
														key={condition + idx}
													/>
												);
											} else return undefined;
										})}
										{isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft' &&
											<FormAddingConditionRun
												setChangeFlg={setChangeFlg}
											/>
										}
									</div>
								}

								{visibleBlockConfig === 'actions' &&
									<div className={styles.dataTopActions}>
										<div className={styles.dataTopActionsBlock}>
											{/* канал */}
											<FormControl fullWidth>
												<Autocomplete
													freeSolo={isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft'}
													options={Array.from(
														new Set(
															(Object.keys(endpointElem.endpoint.actions || {}))
																.concat(channelList.data.map(channel => channel.name), 'default')
														))}
													value={inputChannel}
													onChange={(_, value) => setInputChannel(value ? value : '')}
													noOptionsText={<div className={styles.dataTopActionsNoDataTitle}>{translate('notFound')}</div>}
													renderInput={(params) =>
														<TextField
															{...params}
															label={translate('configEndpoint_inputChannel')}
															onChange={(e) => isAccess(SES.ENDPOINT_EDIT) && setInputChannel(e.target.value)}
															InputLabelProps={{
																style: {
																	fontSize: 13,
																},
															}}
															sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
														/>
													}
													sx={{
														".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
														".MuiInputBase-input": { marginTop: -1 },
													}}
													getOptionLabel={option => option}
													renderOption={(props, option) => {
														return (
															<span {...props} style={{ fontSize: 13, color: colorPrimary }}>
																{option}
															</span>
														);
													}}
												/>
											</FormControl>
										</div>

										{/* действия */}
										<div className={styles.dataTopActionsList}>
											{endpointElem.endpoint.actions[inputChannel]?.length > 0 && endpointElem.endpoint.actions[inputChannel].map((action, idx) => (
												<ActionEvent
													key={`${action.action}${idx}${action.action === 'say' && action.type + (action.type === 'external' ? action.script : action.type === 'internal' ? action.service : action.text.toString())}`}
													action={action}
													idx={idx}
													channel={inputChannel}
													setChangeFlg={setChangeFlg}
													itsAction='endpoint'
												/>
											))}

											{isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft' &&
												<FormAddingAction
													channel={inputChannel}
													itsAction='endpoint'
													setChangeFlg={setChangeFlg}
												/>
											}
										</div>
									</div>
								}
							</div>

							{activeRobotVersion === 'draft' &&
								<div className={styles.dataButtons}>
									{endpointElem.endpoint.id ?
										<>
											{isAccess(SES.ENDPOINT_EDIT) &&
												<FormControl fullWidth>
													<Button
														variant="outlined"
														disabled={!changeFlg}
														sx={{ fontSize: 11 }}
														onClick={editEndpointHandler}
													>
														{translate('configEndpoint_saveBtn')}
													</Button>
												</FormControl>
											}
											{isAccess(SES.ENDPOINT_DELETE) &&
												<FormControl fullWidth>
													<Button
														variant="outlined"
														sx={{ fontSize: 11 }}
														onClick={() => setShowAlertDialogDel(true)}
														color='error'
													>
														{translate('configEndpoint_deleteBtn')}
													</Button>
												</FormControl>
											}
										</>
										:
										isAccess(SES.ENDPOINT_ADD) &&
										<FormControl fullWidth>
											<Button
												variant="outlined"
												sx={{ fontSize: 11 }}
												onClick={addEndpointHandler}
											>
												{translate('configEndpoint_addBtn')}
											</Button>
										</FormControl>
									}
								</div>
							}
						</div>
					</Fade>
				}

				<AlertDialog
					showAlertDialog={showAlertDialogDel}
					setShowAlertDialog={setShowAlertDialogDel}
					submitHandler={deleteEndpointHandler}
					title='dialog_deleteEndpointTitle'
					description='dialog_deleteEndpointConfirm'
					name={endpointElem.endpoint?.name}
				/>
			</div>
		</Slide>
	);
};

export default ConfigEndpoint;
