import { useEffect, useRef, useState } from 'react';
import { Fade } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearState } from '../../store/sesSlice';
import { clearState as clearStateRobots, getRobotList, selectActiveRobotId, selectRobotList } from '../../store/sesRobotSlice';
import { clearState as clearStateScripts } from '../../store/sesScriptSlice';
import { clearCategoriesList, clearChannelList, getCategoriesList, getChannelList } from '../../store/qasSlice';
import { clearDataServers, getDataServers } from '../../store/serverSlice';
import { clearModel } from '../../store/modelSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { QAS, SERVER, SERVICE, SES } from '../../constants/accessRights';
import { backgroundColorSky } from '../../constants/colors';
import { RequestStatus } from '../../types/statusTypes';
import PageWrapper from '../../HOC/PageWrapper/PageWrapper';
import RobotsControls from '../../components/Controls/RobotsControls/RobotsControls';
import RobotNavbar from '../../components/Navbars/RobotNavbar/RobotNavbar';
import WordCloud from '../../components/WordCloud/WordCloud';
import ConfigDataElem from '../../components/ConfigDataElem/ConfigDataElem';
import ConfigEndpoint from '../../components/ConfigEndpoint/ConfigEndpoint';
import ChatWidget from '../../components/ChatWidget/ChatWidget';
import { IRobotsProps } from './Robots.props';
import styles from './Robots.module.scss';

const Robots = ({ serviceType }: IRobotsProps): JSX.Element => {
	const [showPage, setShowPage] = useState<boolean>(true); // показ страницы
	const [showDataElem, setShowDataElem] = useState<boolean>(false); // показ вкладки настроек элемента данных
	const [showEndpointElem, setShowEndpointElem] = useState<boolean>(false); // показ вкладки настроек конечной точки
	const containerRef = useRef<HTMLDivElement>(null); // ссылка на wrapper
	const [showChatWidget, setShowChatWidget] = useState<boolean>(false); // показ чата

	const dispatch = useAppDispatch();
	const robotList = useAppSelector(selectRobotList); // store - список роботов
	const activeRobotId = useAppSelector(selectActiveRobotId); // store - id активного робота

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		isAccess(SES.ROBOT_LIST) && dispatch(getRobotList()); // получаем список роботов
		isAccess(QAS.CHANNEL_LIST) && dispatch(getChannelList()); // получаем список каналов
		isAccess(QAS.CATEGORY_LIST) && dispatch(getCategoriesList()); // получаем список категорий
		isAccess(SERVER.ADDRESSES) && dispatch(getDataServers({ serviceType })); // получаем данные о серверах

		// автозапрос списка серверов каждые 30 сек
		const interval = setInterval(() => {
			isAccess(SERVER.ADDRESSES) && dispatch(getDataServers({ serviceType }));
		}, 30000);

		// при уходе со страницы
		return () => {
			clearInterval(interval); // удаляем автозапрос получения данных о серверах
			dispatch(clearDataServers()); // очищаем данные по серверам
			dispatch(clearModel()); // очищаем данные модели
			dispatch(clearChannelList()); // очищаем список каналов
			dispatch(clearCategoriesList()); // очищаем список категорий
			dispatch(clearState()); // очищаем state SES
			dispatch(clearStateRobots()); // очищаем state SES роботов
			dispatch(clearStateScripts()); // очищаем state SES скриптов
		};
	}, []);

	return (
		<PageWrapper showPage={showPage} setShowPage={setShowPage} accessToService={[SERVICE.SES, SES.ROBOT_LIST]} alternativeBackground={backgroundColorSky}>
			<>
				<RobotsControls setShowPage={setShowPage} />
				<RobotNavbar />

				<Fade in={true} timeout={700} style={{ transitionDelay: '500ms' }}>
					<div className={styles.wrapper} ref={containerRef}>
						{robotList.status === RequestStatus.IDLE && robotList.data.length > 0 ?
							<>
								<WordCloud type='data' setShowElem={setShowDataElem} />
								<ConfigDataElem showDataElem={showDataElem} setShowDataElem={setShowDataElem} />

								<WordCloud type='endpoints' setShowElem={setShowEndpointElem} />
								<ConfigEndpoint showEndpointElem={showEndpointElem} setShowEndpointElem={setShowEndpointElem} containerRef={containerRef} />

								{isAccess(SES.ASK) && activeRobotId && !showChatWidget &&
									<div className={styles.chatWidgetBtn} onClick={() => setShowChatWidget(prev => !prev)}>
										<FontAwesomeIcon icon={faComments} size='2x' color='#fff' />
									</div>
								}

								{isAccess(SES.ASK) && <ChatWidget showChatWidget={showChatWidget} setShowChatWidget={setShowChatWidget} />}
							</>
							:
							<div className={styles.noData}><div>{translate('noData')}</div></div>
						}
					</div>
				</Fade>
			</>
		</PageWrapper>
	);
};

export default Robots;
